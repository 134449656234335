/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// // import Divider from '@mui/material/Divider';
// import Avatar from '@mui/material/Avatar';
// import IconButton from '@mui/material/IconButton';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';

const Form = () => {
  const theme = useTheme();
 
  // const photos = [
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img25.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img22.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img24.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  // ];

  return (
    <Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
        <Typography variant='h4' align='center'>
        Math Club
        </Typography>
        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        <br/>Mathematics Association organised an activity on “Advertising Modules using Mathematical Concepts” for classes 9 to 12 on 15 July 2022. It was a team event and each team was asked to design a product of their choice and advertise it using Mathematical Concepts. Participants designed variety of products ranging from crispy chips to a fuel-efficient car. The performance showcased their in-depth understanding of the concepts. The idea was to make the products with creativity and present with catchy advertisements. The perfect presentation of ideas and execution captured the attention of the audience.
        </Typography>
        <Typography variant={'subtitle1'} align={'center'} fontWeight={700}>
        <br/>“Creativity is intelligence with knowledge.”
        </Typography>
      </Box>
    </Box>
  );
};

export default Form;
