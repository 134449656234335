// MathClub
import React from 'react';
import MathClub from 'views/MathClub';

const MathClubPage = () => {
  return <MathClub/>;
};

export default MathClubPage;

