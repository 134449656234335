import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { Form } from './components';
import Hero from './Hero';
import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import MathSide from './MathSide';
const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})

analytics.page()

const MathClub= () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
     
     <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Form />
              <Hero/>
            </Grid>
            <Grid item xs={12} md={4}>
             
                <Box marginBottom={4}>
                  <MathSide />
                </Box>
           
              {/* <SidebarNewsletter /> */}
            </Grid>
          </Grid>
        </Container>
      
    </Main>
  );
};

export default MathClub;
